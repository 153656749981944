import { ADD_PERMISSION, PROMOTIONAL_PLANNING_PROMO_CUSTOMER } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Customers", "search-by": "Code,Description,KeyAccountCode,KeyAccount", "ph-search-by": "Search by Code, Description, Key Account Code, Key Account" }, scopedSlots: _vm._u([{ key: "create-buttons", fn: function(ref) {
    var onClick = ref.onClick;
    return [_vm.can(_vm.permissions.add) ? _c("a-button", { staticClass: "ml-2", attrs: { "type": "primary" }, on: { "click": onClick } }, [_vm._v(" Add Customer ")]) : _vm._e()];
  } }]) }, [_c("url-field", { key: "Code", attrs: { "base-url": "/promotion-planning/customers", "data-index": "code", "title": "National Account Code", "sorter": true, "width": 150 } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "National Account", "sorter": true } }), _c("url-field", { key: "KeyAccountCode", attrs: { "data-index": "keyAccountCode", "base-url": "/promotion-planning/customers", "title": "Key Account Code", "sorter": true, "width": 200 } }), _c("text-field", { key: "KeyAccount", attrs: { "data-index": "keyAccount", "title": "Key Account", "sorter": true } }), _c("text-field", { key: "distributionCenter", attrs: { "data-index": "distributionCenter", "title": "Distribution Center", "sorter": true } }), _c("text-field", { key: "RegionDescription", attrs: { "data-index": "regionDescription", "title": "Region", "width": 100, "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListCustomer",
  inject: ["can"],
  data() {
    return {
      permissions: {
        add: ADD_PERMISSION
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListCustomer = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-customer" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "promotion-planning.customers", "api-url": _vm.apiUrl, "create-route": "/promotion-planning/customers/create", "edit-route": "/promotion-planning/customers/:id", "page": _vm.page } }, [_c("list-customer")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListCustomer
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_CUSTOMER,
      itemsMenu: [
        {
          title: "Master Files",
          path: ""
        },
        {
          key: "customer",
          title: "Customers",
          path: "/promotion-planning/customers"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
